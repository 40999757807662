<template>
  <div class="tlsite-wrapper">
    <main class="tlsite-bigcontent">
      <div class="tlsite-loginbox">
        <h3>oopsie woopsie</h3>
        <p>welp, something dun fucked up</p>
        <RouterLink class="button" :to="{ name: 'home' }">Home</RouterLink>
      </div>
    </main>
  </div>
</template>
